import normalize from "emotion-normalize"

export const globalStyles = [
  normalize,
  {
    [["*", "*:before", "*:after"]]: {
      boxSizing: "inherit",
    },

    html: {
      // Default font styles
      fontFamily: "'Gotham A', 'Gotham B', Gotham, Helvetica, sans-serif",
      fontStyle: "normal",
      fontSize: "var(--text)",
      lineHeight: 1.25,
      wordBreak: "break-word",
      boxSizing: "border-box",
      color: "#000",
      background: "#F4F4F4",

      // Improve browser font rendering
      textRendering: "optimizeLegibility",
      fontFeatureSettings: "'kern' 1",
      fontKerning: "normal",
      WebkitFontSmoothing: "antialiased",
      MozOsxFontSmoothing: "grayscale",
    },

    [["h1", "h2", "h3", "h4", "h5", "h6"]]: {
      margin: 0,
      fontSize: "inherit",
    },

    a: {
      color: "inherit",
    },

    img: {
      maxWidth: "100%",
    },
  },
]
