import React from "react"
import { Global } from "@emotion/core"
import { globalStyles } from "../base"

import mroLogo from "../images/marathon-oil.png"
import mpcLogo from "../images/marathon-petroleum.png"

const Page = () => (
  <>
    <Global styles={globalStyles} />
    <div
      css={{
        minHeight: "100vh",
        padding: "90px 45px",
        margin: "0 auto -5px",
        maxWidth: 942 + 90,
        "@media (max-width: 767px)": {
          padding: "75px 30px",
        },
      }}
    >
      <div
        css={{
          maxWidth: 756,
          fontWeight: 500,
          fontSize: 22,
          lineHeight: 1.3,
          textAlign: "center",
          color: "#565A5C",
          margin: "0 auto 80px",
        }}
      >
        <div
          css={{
            color: "#9F9F9F",
            fontWeight: 700,
            fontSize: 16,
            letterSpacing: "0.11em",
            textTransform: "uppercase",
            lineHeight: 1.4,
            marginBottom: 16,
          }}
        >
          Welcome
        </div>
        <div>
          As of 2011, Marathon Oil Corporation and Marathon Petroleum
          Corporation are two completely separate entities — each positioned to
          deliver continued shareholder growth.
        </div>
      </div>

      <div
        css={{
          display: "flex",
          "@media (max-width: 767px)": {
            display: "block",
          },
          a: {
            fontWeight: "bold",
            fontSize: 13,
            lineHeight: 1.4,
            letterSpacing: "0.09em",
            textTransform: "uppercase",
            color: "#828181",
            textDecoration: "none",
            borderBottom: "1.5px solid currentColor",
            paddingBottom: 5,
            transition: "color 250ms",
            ":hover": {
              color: "#00A9DF",
            },
          },
        }}
      >
        <div
          css={{
            flex: "1 1 452px",
            marginRight: 38,
            "@media (max-width: 767px)": {
              marginRight: 0,
              marginBottom: 60,
            },
          }}
        >
          <img src={mroLogo} alt="Marathon Oil" css={{ height: 57 }} />

          <p css={{ margin: "45px 0" }}>
            Marathon Oil is an independent exploration and production (E&amp;P)
            company based in Houston. We’re focused on the most significant
            oil-rich resource plays in the U.S. — the Eagle Ford in Texas,
            Permian in New Mexico, STACK and SCOOP in Oklahoma, and the Bakken
            in North Dakota. We also have international operations in Equatorial
            Guinea. Our stock trades on the New York Stock Exchange under the
            ticker symbol: MRO.
          </p>

          <a href="https://www.marathonoil.com/">
            Enter Site
            <Arrow />
          </a>
        </div>

        <div css={{ flex: "1 1 452px" }}>
          <img
            src={mpcLogo}
            alt="Marathon Petroleum"
            css={{ height: 57, objectFit: "contain" }}
          />

          <p css={{ margin: "45px 0" }}>
            Marathon Petroleum Corporation (MPC) is a leading, integrated,
            downstream energy company headquartered in Findlay, Ohio. The
            company operates the nation’s largest refining system. MPC’s
            marketing system includes branded locations across the United
            States, including Marathon brand retail outlets. MPC also owns the
            general partner and majority limited partner interest in MPLX LP, a
            midstream company that owns and operates gathering, processing, and
            fractionation assets, as well as crude oil and light product
            transportation and logistics infrastructure.
          </p>

          <a href="https://www.marathonpetroleum.com/">
            Enter Site
            <Arrow />
          </a>
        </div>
      </div>
    </div>

    <div css={{ height: 5, background: "#828181" }} />
  </>
)

export default Page

const Arrow = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="14"
    fill="none"
    css={{ marginLeft: 10, transform: "translateY(2px)" }}
  >
    <path
      stroke="currentColor"
      strokeLinecap="square"
      strokeWidth="1.5"
      d="M1.5 7h16.32M18.53 6.93l-5.46-5.48M18.58 7.12l-5.46 5.48"
    />
  </svg>
)
